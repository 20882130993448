<template>
  <div class="element materialShadow">
    <p>Du har endnu ikke fået tildelt en besøgsplan.</p>
  </div>
</template>

<script>
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '@/components/Mixins/Translate';

  /**
   * This component is used for showing a sign to external users without a visit plan
   * when they attempt to take a picture.
   */
  export default {
    name: 'no-take-picture',
    mixins: [translate],
    emits: [ComponentEventNames.didLoad],
    mounted() {
      this.$emit(ComponentEventNames.didLoad);
    },
  };
</script>

<style scoped lang="scss">
  .element {
    height: 200px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
</style>
